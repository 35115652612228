.script-outer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .topTabs-outer {
    overflow-x: auto;

    .top-tabs {
      display: flex;
      align-items: center;
      column-gap: 10px;
      width: 561px;
      margin: 0px auto;
      .active-div {
        background-color: var(--primary) !important;
      }
      .active-agent {
        color: var(--primary);
      }
      .tab-agent {
        color: var(--grey);
      }
      .top-tab {
        display: flex;
        align-items: center;
        column-gap: 6px;
        .number-div {
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background-color: var(--grey);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
          p {
            color: var(--white);
          }
        }
      }
      .top-line {
        max-width: 85px;
        min-width: 30px;
        width: 100%;
        height: 1px;
        background-color: var(--grey);
      }
    }
  }
  .script-sec {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    border-radius: 9.977px;
    background: var(--white);
    padding: 24px 20px;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 30px;
    }
    textarea {
      border-radius: 9.977px;
      background: var(--lightwhite);
      border: none;
      padding: 12px 30px;
      font-size: 16px;
      line-height: 187%;
      height: 400px;
      overflow-y: auto;
      &:disabled {
        opacity: 0.5;
      }
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 1440px) {
  .script-outer {
    .script-sec {
      row-gap: 15px;
      padding: 20px;
      textarea {
        padding: 10px 20px;
        font-size: 15px;
        line-height: 150%;
      }
      .bottom {
        button {
          font-size: 18px;
          padding: 15px !important;
          width: 120px !important;
          span {
            line-height: 100% !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .script-outer {
    .script-sec {
      .head {
        button {
          padding: 10px 15px !important;
          width: 80px !important;
        }
      }
      textarea {
        padding: 10px 15px;
        font-size: 14px;
        line-height: 140%;
      }
      .bottom {
        button {
          font-size: 16px;
          padding: 13px !important;
          width: 100px !important;
        }
      }
    }
  }
}
