.createAgent-outer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .topTabs-outer {
    overflow-x: auto;

    .top-tabs {
      display: flex;
      align-items: center;
      column-gap: 10px;
      width: 561px;
      margin: 0px auto;
      .active-div {
        background-color: var(--primary) !important;
      }
      .active-agent {
        color: var(--primary);
      }
      .tab-agent {
        color: var(--grey);
      }
      .top-tab {
        display: flex;
        align-items: center;
        column-gap: 6px;
        .number-div {
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background-color: var(--grey);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
          p {
            color: var(--white);
          }
        }
      }
      .top-line {
        max-width: 85px;
        min-width: 30px;
        width: 100%;
        height: 1px;
        background-color: var(--grey);
      }
    }
  }
  .agentCreation-sec {
    border-radius: 9.977px;
    background: var(--white);
    padding: 20px 20px 42px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    .creation-content {
      display: flex;
      flex-direction: column;
      row-gap: 18px;

      .ist,
      .second {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 19px;
      }
      .third {
        .add-base {
          display: flex;
          align-items: center;
          flex-direction: row;
          column-gap: 17px;
          .base-detail {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            column-gap: 15px;
          }
        }
      }

      .add-base {
        padding: 12px 20px;
        border-radius: 10.402px;
        background: var(--lightwhite);
        display: flex;
        flex-direction: column;
        row-gap: 17px;
        .base-detail {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 13px;
          border-radius: 9.977px;
          background: var(--white);
          padding: 12px 20px;
          img {
            width: 31px;
            height: 31px;
            object-fit: cover;
          }
        }
      }
      .selectnmbr-outer {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .number-div {
          display: flex;
          flex-direction: column;
          row-gap: 18px;
          padding: 12px 28px;
          border-radius: 10.402px;
          // min-height: 135px;
          position: relative;
          background: var(--lightwhite);

          // .number-selector {
          //   display: flex;
          //   align-items: center;
          //   justify-content: space-between;
          //   padding: 10px 20px;
          //   border-radius: 9.977px;

          //   .number-options {
          //     position: absolute;
          //     top: 80px;
          //     left: 0;
          //     right: 0;
          //     border-radius: 8px;
          //     background: var(--Background, #fafafa);
          //     max-height: 190px;
          //     overflow-y: auto;
          //     p {
          //       padding: 20px;
          //       border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          //     }
          //   }
          //   .active {
          //     opacity: 1;
          //     z-index: 10;
          //   }
          //   .deactive {
          //     opacity: 0;
          //     z-index: -2;
          //   }
          // }
        }
        .voice-div {
          display: flex;
          flex-direction: column;
          row-gap: 18px;
          padding: 12px 28px;
          border-radius: 10.402px;
          background: var(--lightwhite);
          // min-height: 135px;
          // .voice-top {
          //   display: flex;
          //   align-items: center;
          //   justify-content: space-between;
          //   .gender-radio {
          //     display: flex;
          //     column-gap: 10px;
          //     label {
          //       display: flex;
          //       align-items: center;
          //       column-gap: 10px;
          //       input {
          //         cursor: pointer;
          //         width: 16px;
          //         height: 16px;
          //         border-color: var(--primary);
          //         accent-color: var(--primary);
          //       }
          //     }
          //   }
          // }
          // .voice-bottom {
          //   position: relative;
          //   .customvoices-data {
          //     padding: 5px;
          //     background: white;
          //     position: absolute;
          //     top: 60px;
          //     left: 0;
          //     right: 0;
          //     max-height: 300px;
          //     overflow-y: auto;
          //     p {
          //       padding: 5px 10px;
          //       border-radius: 5px;
          //       line-height: 120%;
          //       &:hover {
          //         background: #fafafa;
          //       }
          //     }
          //   }
          //   .custom-voice {
          //     padding: 10px 20px;
          //     border-radius: 9.977px;
          //     background: var(--white);

          //     input {
          //       outline: none;
          //       border: none;
          //       width: 100%;
          //       color: var(--black);
          //       font-size: 18px;
          //       font-style: normal;
          //       font-weight: 400;
          //       line-height: 166%;
          //       &::placeholder {
          //         color: var(--grey);
          //         opacity: 0.5;
          //       }
          //     }
          //   }
          //   .model-div {
          //     display: flex;
          //     align-items: center;
          //     justify-content: space-between;
          //     column-gap: 30px;
          //     padding: 15px 29px;
          //     border-radius: 9.977px;
          //     background: var(--white);
          //     position: relative;
          //     cursor: pointer;

          //     svg {
          //       transition: all 0.3s linear;
          //     }
          //     .model-content {
          //       position: absolute;
          //       display: flex;
          //       flex-direction: column;
          //       padding-bottom: 7px;
          //       left: 0;
          //       right: 0;
          //       top: 65px;
          //       border-radius: 9.977px;
          //       background: var(--white);
          //       transition: all 0.3s linear;
          //       p {
          //         padding: 12px 28px;
          //         cursor: pointer;
          //         border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          //       }
          //     }
          //     .modal-hide {
          //       display: none;
          //     }
          //     .modal-show {
          //       display: block;
          //     }
          //   }
          // }
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
.selectbaseModal {
  .modal-dialog {
    max-width: 671px !important;
    .modal-content {
      .modal-body {
        padding: 23px 33px;
        display: flex;
        flex-direction: column;
        row-gap: 28px;
        position: relative;
        .close-btn {
          position: absolute;
          top: 23px;
          right: 23px;
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
        .bases {
          display: flex;
          flex-direction: column;
          border-radius: 16px;
          background: rgba(103, 103, 103, 0.05);
          padding: 10px 13px;
          max-height: 400px;
          overflow-y: auto;
          div {
            display: flex;
            align-items: center;
            padding: 13px 8px;
            column-gap: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .createAgent-outer {
    .agentCreation-sec {
      row-gap: 20px;
      .creation-content {
        row-gap: 15px;
        .ist {
          column-gap: 15px;
        }
        .voice-div {
          row-gap: 10px;
          .gender-radio {
            column-gap: 10px;
            label {
              display: flex;
              align-items: center;
              column-gap: 5px;
              input {
                cursor: pointer;
                width: 16px;
                height: 16px;
                border-color: var(--primary);
                accent-color: var(--primary);
              }
            }
          }
        }
        .selectnmbr-outer {
          .number-div {
            padding: 10px 20px;
          }
          .voice-div {
            padding: 10px 20px;
          }
        }
        .bottom {
          button {
            font-size: 18px;
            padding: 15px !important;
            width: 120px !important;
            span {
              line-height: 100% !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .createAgent-outer {
    .agentCreation-sec {
      row-gap: 15px;
      .creation-content {
        row-gap: 15px;
        textarea {
          min-height: 100px;
        }
        .ist {
          column-gap: 10px;
        }
        .voice-div {
          row-gap: 15px;
        }
        .third {
          button {
            padding: 10px !important;
            max-width: 190px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .createAgent-outer {
    .agentCreation-sec {
      padding: 20px;
      .creation-content {
        row-gap: 10px;
        textarea {
          min-height: 120px;
        }
        .ist {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          row-gap: 10px;
        }
        .selectnmbr-outer {
          .number-div {
            padding: 10px 15px;
          }
          .voice-div {
            padding: 10px 15px;
          }
        }
        .add-base {
          padding: 10px 15px;
          row-gap: 10px;
          display: flex;
          flex-direction: column !important;
          .base-detail {
            justify-content: center !important;
            column-gap: 10px;
            padding: 10px 15px;
          }
        }
        .bottom {
          button {
            font-size: 16px;
            padding: 13px !important;
            width: 100px !important;
          }
        }
      }
    }
  }
  .selectbaseModal {
    .modal-dialog {
      // max-width: 400px !important;
      .modal-content {
        .modal-body {
          padding: 20px;
          row-gap: 15px;

          .bases {
            padding: 5px 10px;
            max-height: 400px;
            div {
              padding: 10px 5px;
              column-gap: 10px;
            }
          }
        }
      }
    }
  }
}
