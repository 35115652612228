.knowledgebase-sec {
  border-radius: 16px;
  background: var(--white);
  padding: 20px 20px 36px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }
  .knowledgebase-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    .knowledgebase-card {
      padding: 20px;
      border-radius: 16px;
      background: var(--lightwhite);
      display: flex;
      flex-direction: column;
      row-gap: 11px;
      .card-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 20px;
      }
      .bound {
        padding: 8px 12px;
        position: relative;
        z-index: 1;
        .bound-layer {
          position: absolute;
          border-radius: 500px;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          background: rgba(12, 165, 90, 0.1);
        }
        p {
          color: var(--primary);
          position: relative;
          z-index: 5;
        }
      }
      h5 {
        margin-top: 4px;
      }
    }
  }
}
.knowledgebase-modal {
  .modal-dialog {
    max-width: 633px !important;
    .modal-content {
      .modal-body {
        padding: 20px 72px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 34px;
        position: relative;
        textarea {
          min-height: 154px;
        }
        .modal-inner {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          row-gap: 18px;
          width: 100%;
          .upload-doc {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 9px;
            label {
              width: 100%;
              padding: 10px;
              height: 150px;
              border-radius: 9.977px;
              border: 1px dashed rgba(103, 103, 103, 0.3);
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }
        }
        .close-btn {
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }
  }
}
.content-modal {
  .modal-dialog {
    max-width: 633px !important;
    .modal-content {
      .modal-body {
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 34px;
        position: relative;

        .modal-inner {
          border-radius: 9.977px;
          background: var(--lightwhite);
          padding: 15px 28px;
          min-height: 400px;
        }
        .close-btn {
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }
  }
}
.detail-modal {
  .modal-dialog {
    max-width: 969px !important;
    .modal-content {
      .modal-body {
        padding: 22px;

        width: 100%;
        .detailModal-inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 32px;
          width: 100%;
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 20px;
            width: 100%;
            .add-div {
              display: flex;
              align-items: center;
              column-gap: 10px;
              border-radius: 8px;
              background: var(--gradient);
              position: relative;
              padding: 10px 18px;
              .model-content {
                position: absolute;
                display: flex;
                flex-direction: column;
                width: 200px;
                right: 0;
                top: 50px;
                border-radius: 9.977px;
                background: var(--lightwhite);
                transition: all 0.3s linear;
                border: 1px solid rgba(0, 0, 0, 0.2);

                p {
                  text-align: center;
                  padding: 10px 0px;
                  cursor: pointer;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
              .modal-hide {
                display: none;
              }
              .modal-show {
                display: block;
              }
            }
          }
          .bottom {
            width: 100%;
            overflow-x: auto;
            .table-outer {
              min-width: 923px;

              width: 100%;
              display: flex;
              flex-direction: column;
              row-gap: 10px;
              .table-head {
                border-radius: 8px;
                background: rgba(103, 103, 103, 0.1);
                padding: 10px 0px;
                display: flex;
                align-items: center;
                .first,
                .second,
                .third {
                  padding: 0px 20px;
                }
                .first {
                  width: 24.7%;
                }
                .second {
                  width: 59.6%;
                }
                .third {
                  width: 15.7%;
                }
              }
              .table-content {
                display: flex;
                flex-direction: column;
                border-radius: 16px;
                background: rgba(103, 103, 103, 0.05);
                padding: 10px 0px;
                .table-detrow {
                  width: 100%;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                  display: flex;
                  align-items: center;
                  padding: 10px 0px;
                  .first,
                  .second,
                  .third {
                    padding: 0px 20px;
                    img {
                      width: 25.09px;
                      height: 25.09px;
                      object-fit: cover;
                    }
                  }
                  .first {
                    width: 24.7%;
                  }
                  .second {
                    width: 59.6%;
                    display: flex;
                    align-items: center;
                    column-gap: 7px;
                  }
                  .third {
                    width: 15.7%;
                    display: flex;
                    align-items: center;
                    column-gap: 7px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .knowledgebase-sec {
    row-gap: 15px;
    padding: 20px 15px;
    .head {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    .knowledgebase-cards {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      .knowledgebase-card {
        padding: 15px;
        row-gap: 10px;
        button {
          width: 200px !important;
        }
      }
    }
  }

  .agent-modal {
    .modal-dialog {
      // max-width: 400px !important;
      margin: 0px auto;
      .modal-content {
        .modal-body {
          padding: 60px 20px;
          row-gap: 20px;

          .ist {
            .select-agent {
              .select-inner {
                padding: 12px 20px;
                column-gap: 20px;
              }

              svg {
                width: 20px;
                height: 20px;
              }
              .select-options {
                top: 50px;
                p,
                label {
                  line-height: 130%;
                }
                label {
                  padding: 10px;
                }
                .search-bar {
                  column-gap: 10px;
                  padding: 10px 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .knowledgebase-sec {
    row-gap: 15px;
    padding: 20px 15px;

    .knowledgebase-cards {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
      .knowledgebase-card {
        padding: 15px;
        row-gap: 10px;
        button {
          width: 200px !important;
        }
      }
    }
  }
  .knowledgebase-modal {
    .modal-dialog {
      // max-width: 400px !important;
      margin: 0px auto;
      .modal-content {
        .modal-body {
          padding: 20px;
          row-gap: 20px;
          .close-btn {
            top: 10px;
            right: 10px;
          }
          div {
            row-gap: 15px;
          }
        }
      }
    }
  }
}
