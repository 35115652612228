@font-face {
  font-family: "Carmen Sans Regular";
  src: url("/src/assets/fontfamily/CarmenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Carmen Sans Medium";
  src: url("/src/assets/fontfamily/CarmenSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Carmen Sans Semibold";
  src: url("/src/assets/fontfamily/CarmenSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Carmen Sans Bold";
  src: url("/src/assets/fontfamily/CarmenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Carmen Sans Extrabold";
  src: url("/src/assets/fontfamily/CarmenSans-ExtraBold.ttf") format("truetype");
}

.Carmen-regular-font {
  font-family: "Carmen Sans Regular";
  font-weight: 400;
}
.Carmen-medium-font {
  font-family: "Carmen Sans Medium";
  font-weight: 500;
}
.Carmen-semibold-font {
  font-family: "Carmen Sans Semibold";
  font-weight: 600;
}
.Carmen-bold-font {
  font-family: "Carmen Sans Bold";
  font-weight: 700;
}
.Carmen-extrabold-font {
  font-family: "Carmen Sans Extrabold";
  font-weight: 800;
}
