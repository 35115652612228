.remindersHistory-sec {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  position: relative;
  padding: 20px;
  border-radius: 9.977px;
  background: var(--white);
  max-height: calc(100vh - 146px);
  overflow-y: auto;
  .reminderhistory-divs {
    display: flex;
    flex-direction: column;
    row-gap: 22px;
    .history {
      padding: 24px 33px 41px 33px;
      border-radius: 16px;
      background: var(--lightwhite);
      display: flex;
      flex-direction: column;
      row-gap: 13px;
      position: relative;
      textarea {
        background: var(--white);
        min-height: 144px;
      }
      p {
        position: absolute;
        bottom: 4px;
        right: 33px;
      }
      .icon-div {
        position: absolute;
        display: flex;
        align-items: center;
        column-gap: 20px;
        position: absolute;
        top: 22px;
        right: 33px;
        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .remindersHistory-sec {
    row-gap: 20px;
    .reminderhistory-divs {
      row-gap: 15px;
      .history {
        .icon-div {
          column-gap: 15px;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .remindersHistory-sec {
    row-gap: 15px;
    padding: 15px;
    .reminderhistory-divs {
      row-gap: 10px;
      .history {
        padding: 24px 15px 40px 15px;
        row-gap: 10px;
        p {
          right: 15px;
        }
        .icon-div {
          column-gap: 15px;
          right: 15px;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
