.input-sec {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  .input-div {
    display: flex;
    align-items: center;
    border-radius: 9.977px;
    background: var(--lightwhite);
    padding: 14px 12px;
    column-gap: 10px;
    input {
      border: none;
      outline: none;
      color: var(--dark);
      font-family: "Carmen Sans Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: transparent;
      width: 100%;

      &::placeholder {
        color: var(--grey);
        opacity: 0.5;
      }
    }
    svg {
      cursor: pointer;
    }
  }
}
