h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-style: normal;
  line-height: normal;
}
h1 {
  font-size: 34px;
}
h2 {
  font-size: 26px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
p {
  font-size: 14px;
}
.small-para {
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}

@media screen and (max-width: 1440px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 1024px) {
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 15px;
  }
  p {
    font-size: 13px;
  }
}
