.error-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  row-gap: 60px;
  max-width: 900px;
  margin: 0px auto;
  img {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .error-sec {
    row-gap: 30px;

    button {
      max-width: 180px !important;
    }
  }
}
