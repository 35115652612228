.dash-main {
  width: calc(100% - 473px);
  margin-left: auto;
  margin-right: 100px;
  margin-top: 126px;
  min-height: 100vh;
  height: 100%;
}
.dashboard-sec {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  .creation-box {
    display: flex;
    align-items: center;
    padding: 66px 40px;
    gap: 12px;
    border-radius: 16px;
    background: var(--white);
    .ist,
    .second {
      border-radius: 16px;
      background: var(--lightwhite);
      padding: 24px 30px;
      min-height: 202px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .ist {
      row-gap: 30px;
      width: 24.8%;
      min-width: 240px;
      position: relative;
      z-index: 2;

      .active-circle {
        border-radius: 500px;
        background: rgba(12, 165, 90, 0.1);
        padding: 13px;
        min-width: 73px;
        min-height: 73px;
      }
    }
    .second {
      width: 100%;
      row-gap: 15px;
      button {
        margin-top: 5px;
      }
    }
  }
  // .create-box {
  //   display: flex;
  //   flex-direction: column;
  //   row-gap: 10px;
  //   border-radius: 16px;
  //   background: var(--white);
  //   padding: 95px;
  //   width: 100%;
  //   h5 {
  //     margin-bottom: 5px;
  //   }
  // }
  .templates-div {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .template-cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 21px;
      .template {
        padding: 20px 20px 0px 20px;
        border-radius: 16px;
        background: var(--white);
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          .bound {
            padding: 5px 12px;
            position: relative;
            z-index: 1;
            .bound-layer {
              position: absolute;
              border-radius: 8px;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              z-index: 2;
              opacity: 0.2;
              background: var(--secondary);
            }
            p {
              position: relative;
              z-index: 5;
            }
          }
        }
        button {
          height: 9px;
          padding: 0px !important;
          img {
            height: 0px;
          }
          span {
            display: none;
          }
        }
        &:hover {
          button {
            height: auto;
            padding: 13px 26px !important;
            img {
              height: 24px;
            }
            span {
              display: block;
            }
          }
        }
      }
    }
  }
}
.create-modal {
  .modal-dialog {
    max-width: 633px !important;
    .modal-content {
      .modal-body {
        padding: 60px 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 25px;
        position: relative;
        h2 {
          text-align: center;
        }
        .close-btn {
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .dash-main {
    width: calc(100% - 400px);
    margin-right: 50px;
    margin-top: 100px;
  }
  .dashboard-sec {
    row-gap: 20px;
  }
}

@media screen and (max-width: 1280px) {
  .dash-main {
    width: calc(100% - 310px);
    margin-right: 30px;
  }
  .dashboard-sec {
    row-gap: 20px;
    .creation-box {
      padding: 40px 15px;
      gap: 10px;
      .ist,
      .second {
        padding: 20px;
      }
      .ist {
        row-gap: 30px;
        width: 30%;
        min-width: 160px;
        position: relative;
        z-index: 2;

        .active-circle {
          border-radius: 500px;
          background: rgba(12, 165, 90, 0.1);
          padding: 13px;
          min-width: 73px;
          min-height: 73px;
        }
      }
      .second {
        width: 40%;
        row-gap: 15px;
        button {
          margin-top: 5px;
        }
      }
    }
    // .create-box {
    //   display: flex;
    //   flex-direction: column;
    //   row-gap: 10px;
    //   border-radius: 16px;
    //   background: var(--white);
    //   padding: 95px;
    //   width: 100%;
    //   h5 {
    //     margin-bottom: 5px;
    //   }
    // }
    .templates-div {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      .template-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 21px;
        .template {
          padding: 20px 20px 0px 20px;
          border-radius: 16px;
          background: var(--white);
          display: flex;
          flex-direction: column;
          row-gap: 12px;

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 12px;
            .bound {
              padding: 5px 12px;
              position: relative;
              z-index: 1;
              .bound-layer {
                position: absolute;
                border-radius: 8px;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                opacity: 0.2;
                background: var(--secondary);
              }
              p {
                position: relative;
                z-index: 5;
              }
            }
          }
          button {
            height: 9px;
            padding: 0px !important;
            img {
              height: 0px;
            }
            span {
              display: none;
            }
          }
          &:hover {
            button {
              height: auto;
              padding: 13px 26px !important;
              img {
                height: 24px;
              }
              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .dash-main {
    width: 100%;
    margin-right: 0px;
    padding: 40px 20px;
    margin-top: 0px;
  }
  .dashboard-sec {
    row-gap: 20px;

    .creation-box {
      padding: 20px 10px;
      .ist,
      .second {
        padding: 15px;
      }
    }

    .templates-div {
      .template-cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 15px;
      }
    }
  }
  .create-modal {
    .modal-dialog {
      max-width: 500px !important;
      .modal-content {
        .modal-body {
          padding: 60px 20px;
          row-gap: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .dash-main {
    padding: 40px 20px;
  }
  .dashboard-sec {
    row-gap: 20px;
    .creation-box {
      flex-direction: column;
      .ist,
      .second {
        width: 100%;
      }
    }
    .templates-div {
      .template-cards {
        .template {
          button {
            height: auto;
            padding: 13px 26px !important;
            img {
              height: 24px;
            }
            span {
              display: block;
            }
          }
        }
      }
    }
  }
}
