.header-sec {
  position: absolute;
  top: 34px;
  right: 40px;
  width: calc(100% - 400px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 60px;
  .right {
    display: flex;
    align-items: center;
    column-gap: 25px;
    .notification {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      position: relative;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .active-notification {
      position: relative;
      .message-hide {
        opacity: 0;
        z-index: -9 !important;
      }
      .message-show {
        opacity: 1;
        z-index: unset;
      }
      .notification-messages {
        position: absolute;
        top: 49px;
        right: -7px;
        width: 206px;
        border-radius: 16px;
        background: var(--white);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
        padding: 9px 8px 26px 8px;
        z-index: 4;
        .main {
          padding: 4px 10px;
        }
        .messages {
          display: flex;
          flex-direction: column;
          .message {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            position: relative;
            min-height: 34px;
            .time {
              position: absolute;
              right: 10px;
              bottom: 4px;
            }
          }
        }
      }
    }
    .user {
      // padding-left: 25px;
      // border-left: 1px solid var(--primary);
      display: flex;
      align-items: center;
      column-gap: 15px;
      .user-img {
        padding-top: 2px;
        width: 44.8px;
        height: 44.8px;
        border-radius: 50%;
        background: var(
          --2-Color-Gradient,
          linear-gradient(90deg, #0ca55a 0%, #344e41 100%)
        );
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100%;
        }
      }
    }
    .hamburger-react {
      display: none;
    }
  }
}

@media screen and (max-width: 1440px) {
  .header-sec {
    position: absolute;
    top: 30px;
    right: 50px;
    width: calc(100% - 400px);
  }
}

@media screen and (max-width: 1024px) {
  .header-sec {
    position: relative;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 20px;
    padding: 0px 40px;
    column-gap: 30px;
    .hamburger-react {
      display: flex !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .header-sec {
    padding: 0px 20px;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
    .right {
      width: 100%;
      .active-notification {
        .notification-messages {
          top: 49px;
          left: 7px;
        }
      }
      .hamburger-react {
        margin-left: auto;
      }
    }
  }
}
