.signin-sec {
  width: 100vw;
  height: 100vh;
  background-color: var(--lightwhite);
  background-image: url(/assets/images/signinback.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 80px;
  .inner {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 43.667px -43.667px 43.667px 0px rgba(194, 194, 194, 0.1) inset,
      -43.667px 43.667px 43.667px 0px rgba(255, 255, 255, 0.1) inset;
    backdrop-filter: blur(43.66666793823242px);
    max-width: 754px;
    width: 100%;
    max-height: 663px;
    height: 100%;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .back-layer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      width: 315px;
      height: 315px;
      border-radius: 315px;
      opacity: 0.5;
      background: var(--Primary, #0ca55a);
      filter: blur(117.4000015258789px);
    }
    .connect-inner {
      max-width: 356px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 30px;
      .connect-inner-bottom {
        display: flex;
        flex-direction: column;
        row-gap: 7px;
        .gif-div {
          width: 262px;
          height: 262px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .main-gif {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
          }
          .connect-circle {
            width: 214px;
            height: 214px;
            background-color: #e0ffed;
            filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 2;
            .dots-gif {
              position: absolute;
              bottom: 22px;
            }
          }
        }
        a {
          font-size: 16px;
          text-align: center;
        }
      }
    }
    .inner-content {
      max-width: 356px;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      .top-head {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
      }
      .top {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        .google-account {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 11px;
          padding: 13px 30px;
          width: 100%;
          border-radius: 9.977px;
          background: var(--lightwhite);
          cursor: pointer;
          img {
            width: 21px;
            height: 21px;
            object-fit: cover;
          }
        }
      }
      .center-line {
        opacity: 0.2;
        background: var(--secondary);
        width: 100%;
        height: 1px;
      }
      .bottom {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .inputs {
          display: flex;
          flex-direction: column;
          row-gap: 16px;
        }
        .bottom-anchors {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 30px;
          .signup-link {
            font-size: 14px;
          }
        }
      }
    }
  }
  .outer-content {
    position: absolute;
    bottom: 15px;
    max-width: 356px;
    text-align: center;
    p {
      line-height: 150%;
    }
  }
}

@media screen and (max-width: 1280px) {
  .signin-sec {
    padding: 80px 60px;
    height: unset;
    min-height: 100vh;
    .inner {
      max-width: 600px;
      max-height: 550px;
      padding: 40px;
      .back-layer {
        width: 270px;
        height: 270px;
        border-radius: 270px;
      }
      .connect-inner {
        .connect-inner-bottom {
          .gif-div {
            width: 250px;
            height: 250px;
            .connect-circle {
              width: 200px;
              height: 200px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .signin-sec {
    height: 100%;
    min-height: 100vh;
    background-color: var(--lightwhite);
    background-image: unset;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .inner {
      max-height: unset;
      height: 100%;
      padding: 40px 20px;
      max-width: 400px;
      .back-layer {
        z-index: -1;
        width: 50vw;
        height: 50vh;
        border-radius: 50%;
      }
      .connect-inner {
        row-gap: 20px;
        .connect-inner-bottom {
          .gif-div {
            width: 200px;
            height: 200px;
            .connect-circle {
              width: 170px;
              height: 170px;
            }
          }
          a {
            font-size: 14px;
          }
        }
      }
      .inner-content {
        row-gap: 15px;
        .top {
          row-gap: 10px;
          .google-account {
            column-gap: 10px;
            padding: 12px 30px;
          }
        }

        .bottom {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          .inputs {
            row-gap: 10px;
          }
          .bottom-anchors {
            column-gap: 20px;
          }
        }
      }
    }
    .outer-content {
      position: unset;
      bottom: unset;
    }
  }
}
