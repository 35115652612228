.inputdash-sec {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
  width: 100%;
  input,
  textarea {
    padding: 12px 28px;
    color: var(--grey);
    line-height: 166%;
    border-radius: 9.977px;
    background: var(--lightwhite);
    border: none;
    &::placeholder {
      opacity: 0.2;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  input {
    font-size: 18px;
  }
  textarea {
    font-size: 16px;
  }
}

@media screen and (max-width: 1440px) {
  .inputdash-sec {
    input,
    textarea {
      padding: 10px 20px;
      line-height: 150%;
    }
    input {
      font-size: 16px;
    }
    textarea {
      font-size: 15px;
    }
  }
}
@media screen and (max-width: 600px) {
  .inputdash-sec {
    input,
    textarea {
      padding: 10px 15px;
      line-height: 140%;
    }
    input {
      font-size: 14px;
    }
    textarea {
      font-size: 14px;
    }
  }
}
