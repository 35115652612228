.agents-sec {
  border-radius: 16px;
  background: var(--white);
  padding: 20px 20px 36px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .agents-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    .agent-card {
      padding: 12px 20px 20px 20px;
      border-radius: 16px;
      background: var(--lightwhite);
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      .card-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 30px;
        .left {
          display: flex;
          align-items: center;
          column-gap: 6px;
          .bound {
            padding: 5px 12px;
            position: relative;
            z-index: 1;
            .bound-layer {
              position: absolute;
              border-radius: 8px;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              z-index: 2;
              opacity: 0.2;
              background: var(--secondary);
            }
            p {
              position: relative;
              z-index: 5;
            }
          }
        }
        .right {
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
      }
    }
  }
  .form-check-input {
    cursor: pointer;

    &:checked {
      background-color: #25704b;
      border-color: #25704b;
    }
    &:focus {
      border-color: #25704b;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(5, 139, 72, 0.25);
    }
  }
}

.delete-modal {
  .modal-dialog {
    max-width: 488px !important;
    .modal-content {
      .modal-body {
        padding: 88px 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 39px;
        position: relative;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 16px;
          button {
            width: 100%;
          }
        }
        .close-btn {
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .agents-sec {
    row-gap: 20px;
    .head {
      button {
        padding: 10px 15px !important;
        width: 80px !important;
      }
    }
    .agents-cards {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 15px;
      .agent-card {
        width: 100%;

        .card-top {
          column-gap: 20px;
          .right {
            width: 30px;
            height: 30px;
            object-fit: cover;
          }
        }
      }
    }
  }
  .delete-modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 60px 20px;
          row-gap: 20px;
        }
      }
    }
  }
}
