.sidebar-sec {
  border-radius: 16px;
  background: var(--white);
  width: 280px;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  position: fixed;
  top: 20px;
  left: 20px;
  height: calc(100vh - 40px);
  overflow-y: auto;
  transition: all 0.4s linear;
  .top {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 123.84px;
      height: 27.617px;
      object-fit: cover;
      cursor: pointer;
    }
  }
  .center {
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    a {
      display: flex;
      align-items: center;
      padding: 13px 20px;
      column-gap: 12px;
    }
    a.active {
      border-radius: 8px;
      background: var(--gradient);
      position: relative;
      h5 {
        color: var(--white);
      }
      img {
        filter: grayscale(1) invert(1) brightness(2);
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -24px;
        width: 7px;
        border-radius: 0px 5px 5px 0px;
        background: var(--primary);
      }
    }
  }
  .bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 13.5px;
  }
}
@media screen and (max-width: 1280px) {
  .sidebar-sec {
    width: 220px;
    .top {
      padding-bottom: 20px;
    }
    .center {
      padding: 0px 15px;
      a {
        padding: 13px 15px;
        column-gap: 10px;
      }
      a.active {
        &::before {
          left: -13px;
          width: 5px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .sidebar-sec {
    z-index: 99;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    width: 250px;
    .center {
      .ist {
        padding: 10px 20px;
      }
      a.active {
        &::before {
          left: -15px;
        }
      }
    }
    .bottom {
      button {
        width: 220px !important;
      }
    }
  }
  .activesidebar {
    left: 20px;
  }
  .closesidebar {
    left: -300px;
  }
}
