@import url(./colors.scss);
@import url(./typography.scss);
@import url(./fontfamily.scss);

* {
  line-height: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.customscrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
}
body {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
.row,
.col,
.nav-item,
.nav-link,
.navbar-brand {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
.row > * {
  padding-left: 0;
  padding-right: 0;
}
body {
  overflow-x: hidden;
  font-family: "Carmen Sans Regular";
  font-weight: 400;
}
/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: normal;
  overflow-x: hidden;
}

ol,
ul {
  list-style: none;
}

/* HTML: <div class="loader"></div> */
.spinner-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;

  margin: auto;
}
.spinner {
  width: 50px;
  height: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #0f9e58;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  outline: none;
  border: none;
}
a {
  text-decoration: none;
}
textarea {
  outline: none;
}
input {
  outline: none;
  &::-webkit-inner-spin-button {
    display: none;
  }
  &::-webkit-outer-spin-button {
    display: none;
  }
}
input:-internal-autofill-selected {
  background: transparent !important;
  background-color: transparent !important;
}
.pointer {
  cursor: pointer;
}
.add-anchor {
  color: var(--primary);
  font-family: "Carmen Sans Regular";
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  line-height: 166.282%; /* 166.282% */
}
