.compaignsInrun-sec {
  border-radius: 16px;
  background: var(--white);
  padding: 20px 10px 20px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }
  .contacts {
    padding: 20px;
    border-radius: 16px;
    background: var(--lightwhite);
    display: flex;
    flex-direction: column;

    .contacts-inner {
      display: flex;
      flex-direction: column;
      row-gap: 13px;
      padding: 10px 20px;
      background: var(--white);
      border-radius: 16px;
      .contactTable-outer {
        overflow-x: auto;
        .contact-table {
          min-width: 965px;
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          .table-head {
            background: rgba(103, 103, 103, 0.1);
            display: flex;
            align-items: center;
          }
          .table-body {
            background: rgba(103, 103, 103, 0.05);
            display: flex;
            flex-direction: column;
            max-height: 442px;
            overflow-y: auto;
            padding-bottom: 15px;
            .contact-detail {
              display: flex;
              align-items: center;
              border-bottom: 1px solid #dbdbdb;
            }
          }
          .table-body,
          .table-head {
            border-radius: 16px;

            .second,
            .third,
            .forth,
            .fifth {
              padding: 20px 14px 20px 20px;
            }

            .second {
              width: 30%;
            }
            .third {
              width: 17%;
            }
            .forth {
              width: 23%;
            }
            .fifth {
              width: 15%;
              display: flex;
              align-items: center;
              column-gap: 10px;
              img {
                width: 25px;
                height: 25px;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}
.compaigndetail-modal {
  .modal-dialog {
    max-width: 967px !important;
    .modal-content {
      .modal-body {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        .detailModal-header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          column-gap: 20px;
          .left {
            display: flex;
            flex-direction: column;
            row-gap: 2px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .compaignsInrun-sec {
    .head {
      column-gap: 20px;
      button {
        padding: 10px 15px !important;
        width: 80px !important;
      }
    }
  }
}
