.history-sec {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background: var(--white);
  padding: 20px;
  border-radius: 9.977px;
  .dates {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    .dates-inner {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
      .search-div {
        display: flex;
        border-radius: 9.977px;
        align-items: center;

        column-gap: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: var(--lightwhite);
        padding: 15px;

        .p-dropdown {
          width: 100%;
        }

        input {
          width: 100%;
          border: none;
          background: transparent;
        }

        .SG-datepicker {
          width: 100%;
          margin: 0px;
          .SG-datepicker__day,
          .SG-datepicker__year,
          .SG-datepicker__month {
            &:hover {
              background-color: var(--primary);
              color: white;
            }
          }
          .SG-datepicker__navigation {
            button {
              &:hover {
                background-color: var(--primary);
                color: white;
              }
            }
          }
          .SG-datepicker__today:hover {
            color: var(--primary);
          }
          .SG-datepicker__day.today {
            color: var(--primary) !important;
          }
          .SG-datepicker__day.selected {
            background-color: var(--primary);
            color: white;
          }
          .SG-datepicker__year.selected,
          .SG-datepicker__month.selected {
            background-color: var(--primary);
            color: white;
          }
          .SG-datepicker__input-container {
            width: 100%;
            .calendar-icon {
              cursor: pointer;
              fill: var(--primary);
            }
            input {
              padding: 0px;
            }
          }
        }
      }
    }
  }

  .contactTable-outer {
    overflow-x: auto;
    .contact-table {
      min-width: 1100px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .table-head {
        background: rgba(103, 103, 103, 0.1);
        display: flex;
        align-items: center;
      }
      .table-body {
        background: rgba(103, 103, 103, 0.05);
        display: flex;
        flex-direction: column;
        max-height: 500px;
        overflow-y: auto;
        padding-bottom: 15px;
        .contact-detail {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #dbdbdb;
        }
      }
      .table-body,
      .table-head {
        border-radius: 16px;

        .second,
        .third {
          padding: 20px 14px 20px 20px;
        }

        .second {
          width: 16%;
        }
        .third {
          width: 14%;
        }
      }
    }
  }
}
.p-dropdown-items {
  padding: 15px;
  background: var(--lightwhite);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.history-modal {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 860px !important;
  }
  .modal-content {
    .modal-body {
      padding: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      .detail-divs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        div {
          padding: 13px 10px;
          border-radius: 8px;
          background: rgba(103, 103, 103, 0.1);
        }
      }
      .call-transcription {
        padding: 20px;
        border-radius: 14.125px;
        border: 0.8px solid rgba(103, 103, 103, 0.2);
        background: var(--white);
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        max-height: 339px;
        overflow-y: auto;
        .left {
          border-radius: 10px;
          background: rgba(103, 103, 103, 0.1);
          padding: 10px;
          width: fit-content;
        }
        .right {
          padding: 10px;
          width: fit-content;
          border-radius: 10px;
          background: rgba(12, 165, 90, 0.1);
          margin-left: auto;
        }
      }

      .recording {
        border-radius: 7.062px;
        border: 1px solid rgba(103, 103, 103, 0.3);
        background: var(--lightwhite);
        padding: 14px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .play-icon {
          width: 24px;
          height: 24px;
        }
        .speaker {
          width: 21px;
          height: 21px;
          margin-top: auto;
        }
      }
    }
  }
}
.p-dropdown-items-wrapper {
  max-height: 220px !important;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
}
