.manualbase-sec {
  border-radius: 16px;
  background: var(--white);
  padding: 20px 20px 36px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: calc(100vh - 165px);
  overflow-y: auto;
  .ist {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    .select-agent {
      position: relative;
      border-radius: 9.977px;
      background: var(--lightwhite);
      .select-inner {
        padding: 15px 28px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 30px;
      }
      h4 {
        opacity: 0.2;
      }
      svg {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
      .select-options {
        position: absolute;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: var(--white);
        padding: 16px 16px 24px 16px;
        left: 0;
        right: 0;
        top: 70px;
        z-index: 3;
        .agents {
          max-height: 200px;
          overflow-y: auto;
        }
        p,
        h5 {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          line-height: 187%;
        }
        p {
          padding: 5px 0px;
        }
        h5 {
          padding: 12px 20px;
          cursor: pointer;
          transition: all 0.2s linear;
          &:hover {
            background: var(--lightwhite);
          }
        }
        .search-bar {
          display: flex;
          align-items: center;
          column-gap: 12px;
          padding: 12px 20px;
          border-radius: 9.977px;
          background: var(--lightwhite);
          input {
            width: 100%;
            outline: none;
            border: none;
            background: transparent;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }
}

@media screen and (max-width: 1440px) {
  .manualbase-sec {
    .bottom {
      button {
        width: 110px !important;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .manualbase-sec {
    .ist {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
      .select-agent {
        .select-inner {
          padding: 10px 15px;
          column-gap: 20px;
        }

        svg {
          width: 20px;
          height: 20px;
        }
        .select-options {
          top: 50px;
          padding: 10px;
          p,
          h5 {
            line-height: 130%;
          }
          h5 {
            padding: 10px;
          }
          .search-bar {
            column-gap: 10px;
            padding: 10px 15px;
            input {
              font-size: 14px;
            }
          }
        }
      }
    }
    .bottom {
      button {
        width: 80px !important;
        padding: 10px !important;
      }
      a {
        font-size: 14px;
      }
    }
  }
}
