.profileinput-sec {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  .input-div {
    display: flex;
    align-items: center;
    border-radius: 9.977px;
    background: var(--lightwhite);
    padding: 12px 28px;
    column-gap: 10px;
    input {
      border: none;
      outline: none;
      color: var(--grey);
      font-size: 18px;
      font-style: normal;
      line-height: normal;
      font-family: "Carmen Sans Regular";
      font-weight: 400;
      background: transparent;
      width: 100%;
    }
    svg {
      width: 23px;
      height: 23px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .profileinput-sec {
    .input-div {
      padding: 12px 15px;
      input {
        font-size: 16px;
      }
    }
  }
}
