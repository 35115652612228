.documentedbase-sec {
  border-radius: 16px;
  background: var(--white);
  padding: 20px 20px 36px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-height: calc(100vh - 165px);
  overflow-y: auto;
  .outer {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    max-height: 600px;
    overflow-y: auto;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }
}

@media screen and (max-width: 600px) {
  .documentedbase-sec {
    .bottom {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
      button {
        width: 220px !important;
      }
    }
  }
}
