.reminders-sec {
  display: flex;
  flex-direction: column;
  row-gap: 34px;
  position: relative;
  padding: 20px 20px 33px 20px;
  border-radius: 9.977px;
  background: var(--white);
  .reminder-img {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 22px;
    height: 22px;
    object-fit: cover;
  }
  textarea {
    min-height: 213px;
  }
  .reminder-content {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    .select-agent {
      position: relative;
      border-radius: 9.977px;
      background: var(--lightwhite);
      .select-inner {
        padding: 15px 28px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 30px;
      }
      h4 {
        opacity: 0.2;
      }
      svg {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
      .select-options {
        position: absolute;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: var(--white);
        padding: 16px 16px 24px 16px;
        left: 0;
        right: 0;
        top: 70px;
        z-index: 3;
        .agents {
          max-height: 220px;
          overflow-y: auto;
        }
        p,
        h5 {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          line-height: 187%;
        }
        p {
          padding: 5px 0px;
        }
        h5 {
          padding: 12px 20px;
          cursor: pointer;
          transition: all 0.2s linear;
          &:hover {
            background: var(--lightwhite);
          }
        }
        .search-bar {
          display: flex;
          align-items: center;
          column-gap: 12px;
          padding: 12px 20px;
          border-radius: 9.977px;
          background: var(--lightwhite);
          input {
            width: 100%;
            outline: none;
            border: none;
            background: transparent;
          }
        }
      }
    }
  }
  .buttons-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }
}

@media screen and (max-width: 1440px) {
  .reminders-sec {
    row-gap: 20px;

    .buttons-div {
      button {
        padding: 15px !important;
        width: 120px !important;
        span {
          line-height: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .reminders-sec {
    row-gap: 15px;
    padding: 20px;
    .reminder-img {
      width: 20px;
      height: 20px;
    }

    .reminder-content {
      .select-agent {
        .select-inner {
          padding: 12px 20px;
          column-gap: 20px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
        .select-options {
          padding: 12px 12px 20px 12px;
          top: 55px;
          z-index: 3;
          p,
          h5 {
            line-height: 130%;
          }
          p {
            padding: 5px 0px;
          }
          h5 {
            padding: 10px 10px;
          }
          .search-bar {
            column-gap: 10px;
            padding: 10px;
          }
        }
      }
    }
    .buttons-div {
      button {
        padding: 12px !important;
        width: 90px !important;
      }
    }
  }
}
