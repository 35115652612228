:root {
  --primary: #0ca55a;
  --secondary: #8eaf9c;
  --gradient: linear-gradient(90deg, #0ca55a 0%, #344e41 100%);
  --white: #fff;
  --lightwhite: #fafafa;
  --dark: #202020;
  --grey: #676767;
  --red: #ff0000;
  --blue: #0781f2;
}

.primarycolor {
  color: var(--primary);
}
.secondarycolor {
  color: var(--secondary);
}
.whitecolor {
  color: var(--white);
}
.lightwhitecolor {
  color: var(--lightwhite);
}
.darkcolor {
  color: var(--dark);
}
.greycolor {
  color: var(--grey);
}
.redcolor {
  color: var(--red);
}
.bluecolor {
  color: var(--blue);
}
