.settings-sec {
  display: flex;
  flex-direction: column;
  row-gap: 17px;
  .settings-tabs {
    .tabs-inner {
      display: flex;
      align-items: center;
      width: fit-content;
      column-gap: 10px;
      border-radius: 8px;
      background: var(--white);
      h5 {
        padding: 10px;
        cursor: pointer;
        color: var(--grey);
      }
      .active {
        color: var(--white);
        background: var(--primary);
        border-radius: 8px;
      }
    }
  }
  .profile-content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    border-radius: 9.977px;
    background: var(--white);
    padding: 30px 20px;
    .content-inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 27px;
      .left {
        padding: 23px 30px 44.8px 30px;
        border-radius: 9.977px;
        background: var(--lightwhite);
        label {
          display: flex;
          align-items: center;
          column-gap: 42px;
          width: fit-content;
          img {
            width: 133.2px;
            height: 133.2px;
            object-fit: cover;
            border-radius: 100%;
            background: var(--gradient);
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        row-gap: 27px;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 30px;
    }
  }
  .password-content {
    max-width: 490px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border-radius: 9.977px;
    background: var(--white);
    padding: 30px 20px;
    .changepassword-div {
      margin-top: 12px;
    }
    .bottom {
      display: flex;
      align-items: center;
      column-gap: 30px;
      justify-content: space-between;
    }
  }

  .hubspot-divs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    .connect-content {
      border-radius: 9.977px;
      background: var(--white);
      padding: 25px 20px;
      display: flex;
      flex-direction: column;
      row-gap: 18px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .settings-sec {
    row-gap: 15px;
    .profile-content {
      row-gap: 20px;
      padding: 25px 20px;
      .content-inner {
        column-gap: 20px;
        .left {
          padding: 20px 25px;
          label {
            column-gap: 20px;
            img {
              width: 110px;
              height: 110px;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .settings-sec {
    display: flex;
    flex-direction: column;
    row-gap: 17px;

    .profile-content {
      row-gap: 20px;
      max-width: 600px;
      .content-inner {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;

        .right {
          row-gap: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .settings-sec {
    .settings-tabs {
      overflow-x: auto;
      .tabs-inner {
        min-width: 470px;
      }
    }
    .profile-content {
      .content-inner {
        row-gap: 10px;
        .left {
          label {
            gap: 10px;
            flex-direction: column;
            width: 100%;
            img {
              width: 90px;
              height: 90px;
            }
          }
        }
      }
    }
    .password-content {
      .bottom {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        button {
          max-width: 160px !important;
        }
      }
    }
    .hubspot-divs {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
  }
}
